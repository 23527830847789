var sliders = (function ($w) {

  var slideWrapper = $(".main-slider"),
    iframes = slideWrapper.find('.embed-player'),
    lazyImages = slideWrapper.find('.slide-image'),
    lazyCounter = 0,
    slideSlick;
  var debounceTime = 50, debounceTimeout = null;

  var $topBgFlip = $(".top-bg-flip"), $top = $(".top");

  if (slideWrapper.length) run();

  function run() {
    var rtl = false;
    if ($("body").css("direction") == "rtl") {
      //rtl = true;
    }
    //console.log(rtl);

    slideWrapper.each (function(){
      var _slideWrapper = $(this);
   
      _slideWrapper.on("init", function(slick){
        slick = $(slick.currentTarget);
        setTimeout(function(){
          playPauseVideo(slick,"play");
        }, 1000);
        resizePlayer();
      });
      _slideWrapper.on("beforeChange", function(event, slick) {
        slick = $(slick.$slider);
        playPauseVideo(slick,"pause");
      });

      _slideWrapper.on("afterChange", function(event, slick) {
        //console.log("afterchange");
        slick = $(slick.$slider);
        var type = slick.find(".slick-current").attr("class").split(" ")[1];
        if (type == "video" || type == "youtube" || type == "vimeo") {
          _slideWrapper.slick("slickPause");
        } else {
          _slideWrapper.slick("slickPlay");
        }
        //if (type != "vimeo") playPauseVideo(slick,"play");
        playPauseVideo(slick,"play");
      });
      _slideWrapper.on("lazyLoaded", function(event, slick, image, imageSource) {
        lazyCounter++;
        if (lazyCounter === lazyImages.length){
          lazyImages.addClass('show');
          _slideWrapper.slick("slickPlay");
        }
      });

      var fade = false;
      if (_slideWrapper.attr("data-fade") == 1 || _slideWrapper.attr("data-fade") == "true") fade = true;
      var arrows = false;
      var lazyLoad = "progressive";
      var autoplay = true;
      var autoplaySpeed = 4000;
      
      var asNavFor = "";
      var asNavForThumbs = "";

      if (_slideWrapper.hasClass("popular-articles")) {
        $(".popular").find(".next").click(function() { $(".popular-articles.main-slider").slick("slickNext")});
        $(".popular").find(".previous").click(function() { $(".popular-articles.main-slider").slick("slickPrev")});
        lazyLoad = "progressive";
        arrows = true;
      }
      if (_slideWrapper.hasClass("instagram") || _slideWrapper.hasClass("banner")) {
        lazyLoad = "ondemand";
      }

      if (_slideWrapper.hasClass("thumb")) {
        asNavFor = '.slider-thumbs';
        asNavForThumbs = _slideWrapper;
        lazyLoad = "progressive";
        var _first = _slideWrapper.find(".item").first();
        if (_first.hasClass("youtube") || _first.hasClass("vimeo") || _first.hasClass("video")) autoplay = false;
      }

      _slideWrapper.slick({
        fade: fade,
        autoplay: autoplay,
        autoplaySpeed:autoplaySpeed,
        lazyLoad: lazyLoad,
        speed:600,
        rtl: rtl,
        arrows:arrows,
        dots:false,
        asNavFor: asNavFor,
        cssEase:"cubic-bezier(0.87, 0.03, 0.41, 0.9)"
      });

      if (_slideWrapper.hasClass("thumb")) {
        $('.slider-thumbs').slick({
          slidesToShow: 6,
          slidesToScroll: 1,
          centerMode: false,
          autoplay: false,
          asNavFor: asNavForThumbs,
          arrows: true,
          infinite: false,
          focusOnSelect: true,
          cssEase:"cubic-bezier(0.87, 0.03, 0.41, 0.9)",
          responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 4
              }
            },
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 2
              }
            }
          ]
        });
      }
    });
    

    $w.on('resize orientationchange', function () {
      debounce(resizePlayer);
    });
    //resizePlayer();
    debounce(resizePlayer);
  }

  // POST commands to YouTube or Vimeo API
  function postMessageToPlayer(player, command){
    if (player == null || command == null) return;
    player.contentWindow.postMessage(JSON.stringify(command), "*");
  }


  // When the slide is changing
  function playPauseVideo(slick, control){
    var currentSlide, slideType, startTime, player, video;

    currentSlide = slick.find(".slick-current");
    slideType = currentSlide.attr("class").split(" ")[1];
    player = currentSlide.find("iframe").get(0);
    startTime = currentSlide.data("video-start");

    if (slideType === "vimeo") {
      switch (control) {
        case "play":
          if ((startTime != null && startTime > 0 ) && !currentSlide.hasClass('started')) {
            currentSlide.addClass('started');
            postMessageToPlayer(player, {
              "method": "setCurrentTime",
              "value" : startTime
            });
          }
          postMessageToPlayer(player, {
            "method": "play",
            "value" : 1
          });
          break;
        case "pause":
          postMessageToPlayer(player, {
            "method": "pause",
            "value": 1
          });
          break;
      }
    } else if (slideType === "youtube") {
      switch (control) {
        case "play":
         /* postMessageToPlayer(player, {
            "event": "command",
            "func": "mute"
          });*/
          postMessageToPlayer(player, {
            "event": "command",
            "func": "playVideo"
          });
          break;
        case "pause":
          postMessageToPlayer(player, {
            "event": "command",
            "func": "pauseVideo"
          });
          break;
      }
    } else if (slideType === "video") {
      video = currentSlide.children("video").get(0);
      if (video != null) {
        if (control === "play"){
          video.play();
        } else {
          video.pause();
        }
      }
    }
  }

  function isMobile() {
    //console.log($w.width());
    if (window.innerWidth < 768) {
      //console.log("isMobile");
      return true;
    } else {
    //  console.log("NotMobile");
      return false;
    }
  }

  // Resize player
  function resizePlayer() {
    if ($topBgFlip) $topBgFlip.height($top.height());
    $('.slider-thumbs').width(10);

    slideWrapper.each (function(){
      var _slideWrapper = $(this);
     // console.log($(this));
      _slideWrapper.css("width","10px");
      
      var _width;// = slideWrapper.parent().width();
      var _height;// = slideWrapper.parent().height();
      var _m = "";
      if (isMobile()) _m = "-mobile";

      if (_slideWrapper.data("width"+_m) == "parent") _width = _slideWrapper.parent().width();
      else _width = _slideWrapper.data("width"+_m);
    
      if (_slideWrapper.data("height"+_m) == "parent") _height = _slideWrapper.parent().height();
      else if (_slideWrapper.data("height"+_m) == "width") _height = _width;
      else _height = _slideWrapper.data("height"+_m);

      //console.log("resizePlayer / Width: " + _width);
     // console.log(_m);
     // console.log(_slideWrapper.data("height"+_m));
      
      //console.log(_width);
      _slideWrapper.width(_width);
      _slideWrapper.height(_height);
      _slideWrapper.find(".item").width(_width);
      _slideWrapper.find(".item").height(_height);
      _slideWrapper.find(".slick-list").width(_width);
      _slideWrapper.find(".slick-list").height(_height);
      _slideWrapper.find("video").width(_width);
      _slideWrapper.find("video").height(_height);

      try { _slideWrapper.slick("setPosition");
    } catch(error) {  }
/*
      if (_slideWrapper.attr("data-fade") == 1 || _slideWrapper.attr("data-fade") == "true") {
        _slideWrapper.find(".slick-track").css("transform","translate3d(0px, 0px, 0px)");
      } else {
        var c=0;
        _slideWrapper.find(".slick-slide").each(function(){
          if ($(this).hasClass("slick-active")) {
            _slideWrapper.find(".slick-track").css("transform","translate3d(" + (-_width*c) + "px, 0px, 0px)");
            _slideWrapper.find(".slick-track").css("transform","translate3d(" + (-_width*c) + "px, 0px, 0px)");
            //console.log(-_width*c);
          }
          c++;
        });
      }
*/
      var _iframes = _slideWrapper.find('.embed-player');
      _iframes.each(function(){
        var current = $(this);
          current.width(_width).height(_height).css({
          left: 0,
          top: 0
        });
      });

    });

    if ($(".article-slider").length) {
      $(".article-slider").height($(".article-slider").width()/1.8);
       $('.slider-thumbs').width($(".article-slider").width());
    }

  }


  function debounce(fn) {
    if(debounceTimeout) clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(fn, debounceTime);
  }

  function resize() {
    debounce(resizePlayer);
  }

  return {
    resize: resize
  };
 
 
})($(window));
 

