(function ($w) {
	var debounceTime = 100, debounceTimeout = null;
	var $sideBar = $(".side-bar");
	var $widget = $(".widget");
	var $widgetFirst = $(".widget.width");
	var $facebookWidget = $(".facebook.widget").find(".widget-box");//$('#facebook-page-plugin');
  var facebookPageId      = $facebookWidget.attr("data-fbpage");//'PrideInvests';//$facebookPage.attr('data-fb-page-id');
  var facebookWidgetCreated = false;
	var facebookWidgetWidth, facebookWidgetHeight;

	if ($widget.length) run();

	function run() {
		attachEvents();
	}

	function attachEvents() {
		$w.resize(function () {
      debounce(checkDimensions);
    });
    checkDimensions();
	}

	function checkDimensions() {
		$('.widget:not(.width)').css("width", "10px");
		$('.widget:not(.width)').css("overflow", "hidden");
		$widget.hide();
		$widgetFirst.show();
		var windowInnerWidth = $("footer").width();
 	  var widget_width = Math.floor($widgetFirst.width());
 	  if (windowInnerWidth < 500) {
 	  	widget_width = windowInnerWidth - 40;//window.innerWidth - 40;
 	  }
 	  if ($('.widget.instagram').hasClass("two") || $('.widget.facebook').hasClass("two") || $('.widget.twitter').hasClass("two")) {
 	  	if (window.innerWidth > 500 && windowInnerWidth <992) {
				widget_width = Math.floor((windowInnerWidth - 40)*0.47);
 	  	}
 	  }

  	
 	  
	  var widget_height = widget_width;
		$('.widget:not(.width)').css("width", "auto");
	  //console.log("window innerwidth = " + window.innerWidth);
	  //console.log("widget_width = " + widget_width);

		$widget.show();
		$widgetFirst.hide();
	  $widget.find(".widget-box").width(widget_width);
	  $widget.find(".widget-box").height(widget_height);

	   if ($(".twitter.widget").length) {
	   		var twitterTimer = setInterval(function(){ 
				if ($(".twitter.widget").find(".widget-box").find("iframe").height()) {
					$(".twitter.widget").find(".widget-box").find("iframe").height(widget_height);
					//$(".twitter.widget").find(".widget-box").width($(".twitter.widget").width());
					clearInterval(twitterTimer);
				}

	   	}, 500);
	   }

	  if (window.innerWidth < 991) {
	  	var width_width_banner = windowInnerWidth - 40;
 	  	//console.log(width_width_banner);
	  	$('.widget.banner').find(".widget-box").width(width_width_banner);
  		$('.widget.banner').find(".widget-box").height(Math.floor(width_width_banner/3.34));
			//widget_width = Math.floor((window.innerWidth - 40)*0.47);
  	}	else {
			$('.widget.banner').find(".widget-box").height(widget_height);
  	}


	  //		if (facebookWidget.length && (widget_width != widget_width || widget_height != widget_height)) {

		if (typeof FB !== 'undefined' && $facebookWidget.length) {
			if (facebookWidgetWidth!=widget_width || facebookWidgetHeight!=widget_height) {
	    	$facebookWidget.empty();
	    	var facebookPageContent = '<div class="fb-page" data-href="https://www.facebook.com/'+facebookPageId+'" data-tabs="timeline" data-width="'+widget_width+'" data-height="'+widget_height+'" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="true"></div>';
	    	$facebookWidget.html(facebookPageContent);
	    	FB.XFBML.parse();
	    	facebookWidgetCreated = true;
	    	//console.log("created");
	    	facebookWidgetWidth = widget_width;
	    	facebookWidgetHeight = widget_height;
	    }
    }

		//console.log($facebookWidget.find("fb-page").length);
		if ($facebookWidget.length && !$facebookWidget.find("fb-page").length && !facebookWidgetCreated) {
			//console.log("Not Created");
			var facebookTimer = setTimeout(function(){ 
					///	console.log("asdasd");
						checkDimensions();
		  }, 2000);
		} 

		if (sliders) sliders.resize();
		if (articlesListing) articlesListing.resize();
 
	}

	function debounce(fn) {
    if(debounceTimeout) clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(fn, debounceTime);
  }
 
})($(window));