(function ($w) {
  var debounceTime = 100;
  var debounceTimeout = null;
 // var gallerySliderCount = 0;
 var $videoSliderWrapper = $(".community-video-slider-wrapper");
 var $videoSlider = $videoSliderWrapper.find(".slider.video");
 var $videoTWrapperClose = $videoSliderWrapper.find(".close");
 var $videoThumb = $(".thumb.video");
 var $videoThumbsWrapper = $(".thumbs-video");
 var sharingLinkVideo ="";

 var $photosSliderWrapper = $(".community-photos-slider-wrapper");
 var $photosSlider = $photosSliderWrapper.find(".slider.photos");
 var $photosWrapperClose = $photosSliderWrapper.find(".close");
 var $photoThumb = $(".thumb.photo");
 var $photoThumbsWrapper = $(".thumbs-photos");
 var sharingLinkPhoto ="";

  run();
  
  function run() {
    if ($videoSliderWrapper.length) {
      initThumbVideos();
      $videoTWrapperClose.click(closeVideoWrapper);
      $videoSlider.on('afterChange', function(event, slick, currentSlide){
        videoSlideChanged(currentSlide);
      });
      if ($videoSliderWrapper.hasClass("show")) videoSlideChanged(0);
      $videoSliderWrapper.find("a.social-icon").addClass("disable").click(videoSocialShareClicked);
    }

    if ($photosSliderWrapper.length) {
      initThumbPhotos();
      $photosWrapperClose.click(closePhotosWrapper);
      $photosSlider.on('afterChange', function(event, slick, currentSlide){
        photoSlideChanged(currentSlide);
      });
      $photosSliderWrapper.find("a.social-icon").addClass("disable").click(photoSocialShareClicked);

    }

   /* $w.resize(function () {
      debounce(updateOnResize);
    });*/
    //initSliders();
  }
  function videoSocialShareClicked(e) {
    e.preventDefault();
    window.open($(this).attr('href')+sharingLinkVideo);
  }
  
  function initThumbVideos() {
    $videoThumb.click(thumbVideoClicked);
  }

  function thumbVideoClicked(e) {
    $videoSlider.slick("slickGoTo",$videoThumb.index($(this)));
    openVideoWrapper();
  }
  
  function videoSlideChanged(index) {
    var $slideVideo = $(".slide-vid-"+index);
    var $slideVideoThumb = $(".thumb.video").eq(index);
    var videoLink = $slideVideoThumb.attr("data-video"); 
    $(".thumb.video").removeClass("active");
    $slideVideoThumb.addClass("active");
    var title = $slideVideoThumb.find(".title").text();
    var subTitle = $slideVideoThumb.find(".sub-title").text();
    var date = $slideVideoThumb.find(".date").text();
    if (date) subTitle = subTitle + "&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;" + date;
    $videoSliderWrapper.find(".slide-title").html(title);
    $videoSliderWrapper.find(".slide-sub-title").html(subTitle);
    $("iframe").attr("src", "");
    $slideVideo.find("iframe").attr("src", videoLink);
    sharingLinkVideo = videoLink;
    //console.log(sharingLinkVideo);
  }

  function openVideoWrapper() {
    scrollToElement($videoSliderWrapper);
    $videoSliderWrapper.addClass("show");
  }
  
  function closeVideoWrapper() {
    $videoSliderWrapper.removeClass("show");
    setTimeout(function() {$("iframe").attr("src", "");},650);
    $(".thumb.video").removeClass("active");
  }

  function scrollToElement($wrapper) {
     $('html, body').animate({
        scrollTop: $wrapper.offset().top -40
    }, 500);
  }
 
  function debounce(fn) {
    if(debounceTimeout) clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(fn, debounceTime); 
  }

  function photoSocialShareClicked(e) {
    e.preventDefault();
    //console.log($(this).attr('href')+sharingLinkPhoto);
    window.open($(this).attr('href')+sharingLinkPhoto);
  }

 function initThumbPhotos() {
    $photoThumb.click(thumbPhotoClicked);
  }

  function thumbPhotoClicked(e) {
    $photosSlider.slick("slickGoTo",$photoThumb.index($(this)));
    openPhotosWrapper();
  }

  function openPhotosWrapper() {
    scrollToElement($photosSliderWrapper);
    $photosSliderWrapper.addClass("show");
  }
  
  function closePhotosWrapper() {
    $photosSliderWrapper.removeClass("show");
    $(".thumb.photo").removeClass("active");
  }

  function photoSlideChanged(index) {
    var $slidePhotoThumb = $(".thumb.photo").eq(index);
    sharingLinkPhoto = $slidePhotoThumb.attr("data-photo"); 
    //console.log(sharingLinkPhoto);
    $(".thumb.photo").removeClass("active");
    $slidePhotoThumb.addClass("active");
    var title = $slidePhotoThumb.find(".title").text();
    var subTitle = $slidePhotoThumb.find(".sub-title").text();
    var date = $slidePhotoThumb.find(".date").text();
    if (date) subTitle = subTitle + "&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;" + date;
    $photosSliderWrapper.find(".slide-title").html(title);
    $photosSliderWrapper.find(".slide-sub-title").html(subTitle);
  }


 /* function updateOnResize() {

  }

  function initSliders() {

	}*/

})($(window));