(function ($w) {

	run();
	
	function run() {
		WebFont.load({
	    google: {
	     families: ['Signika:300,400,600,700']
	     /*families: ['Mirza', 'Signika:300,400,600,700','Pacifico','Amiri','El+Messiri']*/
	    },
	    active: function() {
	    	if (sliders) sliders.resize();
				if (articlesListing) articlesListing.resize();
			/*	increaseArabicFonts($('body'), true);
				increaseArabicFonts($('input'), false);
				increaseArabicFonts($('select'), false);
				increaseArabicFonts($('textarea'), false);
				increaseArabicFonts($('button'), false);*/
	    },
	    
  	});

		// Remember when we started
		//console.time("concatenation");
		//console.timeEnd("concatenation");

	}

	function increaseArabicFonts($_obj, _children) {
		if (_children) $_obj = $_obj.children();
		$_obj.each(function() {
			var family = $(this).css("font-family").split(",")[0];
			if ($(this).children().length) increaseArabicFonts($(this));
			
			if (family == "TheSans") {
				var size = parseInt($(this).css("font-size"));
				//var lineHeight = parseInt($(this).css("line-height"));
				size = parseInt(size*1.3) + "px";
				//lineHeight = parseInt(lineHeight/1.1) + "px";
				$(this).css({'font-size': size});//, 'line-height': lineHeight
			}
    });
	}


})($(window));

	 