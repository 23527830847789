(function ($w) {
	var clickDisabled = false;
	var debounceTime = 100, debounceTimeout = null;
	var $aside = $("aside"), $asideOpenCloseButton = $('.aside-open-close-button');
	run();

	function run() {
		attachEvents();
	}

	function attachEvents() {
		$asideOpenCloseButton.click(toggleAsideMenu);
		$w.resize(function () {
      debounce(checkHeightOnResize);
    });
    checkHeightOnResize();
	}

	function checkHeightOnResize() {
		$aside.height($w.height()-60);
	}

	function toggleAsideMenu() {
		if (!clickDisabled) {
			if ($("body").hasClass('aside-open')) closeAside();
			else openAside();
			disableClick(300);
		}
	}

	function disableClick(_time) {
		clickDisabled = true;
		setTimeout(function() {clickDisabled = false; }, _time);
	}


	function closeAside() {
		$("main").off("click"); $(".top").off("click"); $("footer").off("click");
		$("body").removeClass('aside-open');
	}

	function openAside() {
		$("body").addClass('aside-open');
		$("main").click(closeAside); $(".top").click(closeAside); $("footer").click(closeAside);
	}

	function debounce(fn) {
    if(debounceTimeout) clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(fn, debounceTime);
  }
 
})($(window));