(function ($w) {

	var $form = $('.search-form');
	var $submitButton = $form.find("button");
	var $searchInput = $form.find("input");
	var $sisl = $("header").find(".social-icons-search-language");
	var searchFor;
	var searchBoxOpened = false;

	if ($form.length) run();

	function run() {
		attachEvents();
	}

	function attachEvents() {
		$("header").find(".search-form button").click(headerSearchClicked);
		$form.submit(search);
	}

	function headerSearchClicked() {
		if (searchBoxOpened) closeSearchBox();
		else openSearchBox();
	}

	function openSearchBox() {
		$("header").find(".social-icons-search-language").addClass("shrink");
		$('body').click(closeSearchBoxClickOutside);
	}

	function closeSearchBoxClickOutside(e) {
		 if(!$(e.target).hasClass('search-button') && !$(e.target).hasClass('search-field') ) closeSearchBox();
	}
	function closeSearchBox() {
		$("header").find(".social-icons-search-language").removeClass("shrink");
		$('body').off("click");
	}

	function search(e) {
		e.preventDefault();
		$form = $(this);
		$submitButton = $form.find("button");
		$searchInput = $form.find("input");

		searchFor = sanitizeHtml($searchInput.val());
		
		if (searchFor != "" && searchFor.length >2) {
			window.location.href = ($form.attr("search-page")+"?for="+searchFor);
			//console.log($form.attr("search-page")+"?for="+searchFor);
		}	else $searchInput.focus();
	}


})($(window));