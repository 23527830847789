var articlesListing = (function ($w) {

  var articlesLists = $(".articles-listing");
  var debounceTime = 100, debounceTimeout = null;

  if (articlesLists.length) run();

  function run() {
    //console.log("articlesLists.length" + articlesLists.length);
    
    $w.on('resize orientationchange', function () {
      debounce(checkHeights);
    });
    debounce(checkHeights);
  }
  
  function checkHeights() {

    var minHeight = 0;
    if ($(".side.side-bar").length) minHeight = Math.floor($(".side.side-bar").width());
    if (window.innerWidth <= 991) minHeight = 0;
    //console.log("articlesListing / MinHeight = " + minHeight);

    var i =0;
    articlesLists.each (function(){
      i++;
      if (i<100) {
        var _articleList = $(this);
        var _minHeight = minHeight;
        var _minWidth = 10;
        _articleList.find(".article-listing-box").each (function(){
          var _article = $(this);
          if (!_minWidth) _minWidth = _article.width();
          _article.css("height","auto");
          _article.find(".text").css("height","auto");
          if (_article.height() > _minHeight) _minHeight = _article.height();
        });
        if ((window.innerWidth > 500 && !_articleList.hasClass("articles-listing-side")) || (window.innerWidth > 500 && window.innerWidth < 991 && _articleList.hasClass("articles-listing-side"))) {
          _articleList.find(".article-listing-box").each (function() {
            var _thisHeight=$(this).height();
            if (_thisHeight < _minHeight) $(this).find(".text").height( $(this).find(".text").height() + _minHeight - _thisHeight);
          });
        }
      }
    });
  }

  function isMobile() {
    if (window.innerWidth < 768) {
      //console.log("isMobile");
      return true;
    } else {
    //  console.log("NotMobile");
      return false;
    }
  }

  function debounce(fn) {
    if(debounceTimeout) clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(fn, debounceTime);
  }

  function resize() {
    if (articlesLists.length) debounce(checkHeights);
  }

  return {
    resize: resize
  };
 
 
})($(window));
 

